.background{
    background-image: url('../../img-video/projects.jpeg');
    background-repeat: no-repeat;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    filter: brightness(50%);
}
.projectSection
{
    position: absolute;
    top: 53vh;
    left: 3em;
    color:white;  
}
.projectSection h1
{
    margin: 0;
    font-size: calc(100vw / 21 );
    font-family: 'Purple Purse', cursive;
    letter-spacing: 4px;
}
.heading
{
    margin : 10% 10%;
}
.heading h1, .allprojects h1
{
    font-family: kepler-std-semicondensed-dis;
    font-weight: 600;
    padding-bottom: 0.5em; 
    font-size: calc(100vw/19);
    text-align: center;
}
.heading p
{
    margin-bottom: calc(100vw/20);
    line-height: 1.5;
    font-size: calc(100vw/40);
    text-align: justify;
}
.allprojects
{
    padding: 5% 5%;
    background-color:  rgb(241, 241, 241);
}
.design
{
    margin-bottom: 3em;
}
.slider
{
    margin: 2em 0;
}
.slider img{
    width: 100%;
    height: auto;
} 
.slider h2
{
    display: inline-block;
    font-weight: 500;
    padding-bottom: 0.5em;
    padding-right: 0.5em; 
    font-size: calc(100vw/25);
    text-align: left;
}
.slider a{
    color: black;
}
.slider a:hover{
    color: rgb(221, 88, 88);   
}
.airbnb, .everest, .shopify
{
    padding: 2.5%; 
    position: relative;  
}
.airbnb img, .everest img, .shopify img
{ 
    filter: brightness(35%);
}
.airbnbcenter, .everestcenter, .shopifycenter
{
    position: absolute;
    top:50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -46px;
}
.airbnbcenter p, .everestcenter p, .shopify p
{
    color: white;
    font-weight: 400;
    margin-bottom: 0.5em;
    text-align: center;
}
.projectlink
{
    background-color: transparent;
    transition: background-color 0.5s ease;
    border: 5px solid rgb(221, 88, 88);
    color:white;
    padding: 15px 27px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 3px;
    margin: 4px 2px;
    cursor: pointer;
}
.projectlink:hover
{
    background-color: rgb(221, 88, 88);
}


@media screen and (min-width: 640px) {

    .projectSection
    {
        top: 40vh;
    }
    .heading h1
    {
        font-size: calc(100vw/19);
        text-align: center;
    }
    .heading p
    {
        font-size: calc(100vw/35); 
    }
    .slider h2
    {
        font-size: calc(100vw/30);
    }
    .develop
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .airbnb img, .everest img, .shopify img
    { 
        filter: brightness(60%);
    }
    .airbnbcenter, .everestcenter, .shopifycenter
    {
        opacity: 0
    }
    .airbnb:hover .airbnbcenter
    {
        opacity: 1
    }
    .airbnb:hover img, .everest:hover img, .shopify:hover img
    {
        filter: brightness(35%);
    }
    .everest:hover .everestcenter{
        opacity: 1
    }
    .shopify:hover .shopifycenter{
        opacity: 1
    }
    
}


@media screen and (min-width: 901px) {

    .heading p
    {
        font-size: calc(100vw/50); 
    }
    
    .slider h2
    {
        font-size: calc(100vw/35);
    }
    
    
}
@media screen and (min-width: 1100px) {
    .develop
    {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.navbar
{
    background-color: white;
    position: fixed;
    z-index: 10;
    width: 100%;
}
.yourText {
    /* padding: 0.5em 0.5em; */
    display: grid;
    grid-template-columns: 3.5em  auto ;
    grid-template-areas:  "drop logo"
                          
}
.dropMenu{
    grid-area: drop;
    padding: 0.5em 0 0 0.5em;
}
.dropMenu > button{
    border:none;
    background-color: white;
    color: black;
}
.logo{
    grid-area: logo;
    height: auto;
    text-align: center;
    /* max-width: 50%; */   
}
.logo img
{
    max-height: 45px;
}

@media screen and (min-width: 640px) {
.navbar
{
    padding: 1em 1em 1em 3em;
    position: absolute;
    top: 0;
    left:0;
    z-index: 2;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
}
.yourText
{
    grid-template-columns: 100px auto;
    grid-template-areas: "log men";
}
.dropMenu{
    display: none;
}
.logo
{
    grid-area: log;
    background: transparent;
}
.logo img
{
    max-height: 100px;
    display: block;
}

} 
  
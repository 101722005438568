@import url('https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap');

#myVideo 
{
    position: static;
    top: 0;
    left: 0;
    object-fit: fill;
    width: 100%; 
    height: 100vh;
    box-sizing: border-box;
    padding-top: 2.8125em;
}
.videoSection
{
    position: absolute;
    top: 53vh;
    left: 3em;
    color:white;
    
}
.videoSection h1
{
    margin: 0;
    font-size: calc(100vw / 20 );
    font-family: 'Purple Purse', cursive;
    letter-spacing: 4px;
}
.videoSection a{
    margin-top: 1.5em;
}
.connect, .codestation 
{
    background-color: transparent;
    transition: background-color 0.5s ease;
    border: 1px solid rgb(221, 88, 88);
    color: white;
    padding: 20px 34px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 3px;
    margin: 4px 2px;
    cursor: pointer;
}
.connect:hover
{
    background-color:  rgb(221, 88, 88);
    color: black;
}
.codestation
{
    color: black;
}
.codestation:hover
{
    background-color:  rgb(221, 88, 88);
    color: white;
}

.homeSection
{
    margin-top: -3px;
    padding : 7.5% 5%;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    grid-gap: 1em;
    background-color: rgb(241, 241, 241);
}
.homeImg
{
  border-radius: 1em;
  width: calc(100vw);
  justify-self: center;
}
.homeText h1, .aboutCode h1, .recentproject h1
{
    font-family: kepler-std-semicondensed-dis;
    font-size: calc(100vw/15);
    font-weight: 600;
    padding-bottom: 0.5em 
}
.homeText p
{
    margin-bottom: calc(100vw/20);
    line-height: 1.5;
    font-size: calc(100vw/40);
    text-align: justify;
}
.aboutCode
{
    margin : 10% 10%;
    text-align: center;
}
.aboutCode h1
{
    font-size: calc(100vw/18);  
}
.codetype
{
    margin-bottom: 5em;
}
.codetype article
{
   padding: 1.5em 0; 
}
.codetype h2
{
    font-size: 1.5em;
    margin: 16px 0;
    color: rgb(221, 88, 88);
}
.codetype p 
{
    font-size: 1.2em;   
}
.recentproject
{
    padding: 5% 5%;
    text-align: center;
    background-color:  rgb(241, 241, 241);
}
.develop
{
 margin-bottom: 3em;
}
.airbnb, .shopify
{
    padding: 2.5%; 
    position: relative;  
}
.airbnb img, .shopify img
{ 
    filter: brightness(35%);
}
.airbnbcenter, .shopifycenter
{
    position: absolute;
    top:50%;
    left: 50%;
    margin-left: -100px;
    margin-top: -46px;
}
.airbnbcenter p, .shopify p
{
    color: white;
    font-weight: 400;
    margin-bottom: 0.5em;
    text-align: center;
}





@media screen and (min-width: 640px) 
{
    #myVideo{
        height: 100vh;
        padding-top: 0;
    }
    .videoSection{
        top: 40vh;
    }
    .videoSection h1{
        font-size: calc(100vw / 22 );
    } 
    .homeImg
    {
        width: calc(100vw/1.5)
    }
    .homeText h1
    {
        font-size: calc(100vw/24); 
    }
    .homeText p
    {
        font-size: calc(100vw/40); 
    }
    .aboutCode h1, .recentproject h1
    {
        font-size: calc(100vw/20);
    }
    .codetype article
    {
        width: 25em; 
        margin: 0 auto;
    }
    .develop
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .airbnb img, .shopify img
    { 
        filter: brightness(60%);
    }
    .airbnbcenter, .shopifycenter
    {
        opacity: 0
    }
    .airbnb:hover .airbnbcenter
    {
        opacity: 1
    }
    .airbnb:hover img, .shopify:hover img
    {
        filter: brightness(35%);
    }
    .shopify:hover .shopifycenter{
        opacity: 1
    }
    
    
}


@media screen and (min-width: 901px) 
{
    .homeImg
    {
        width: 300px;
    }
    .homeText p
    {
        font-size: calc(100vw/55); 
    }
    .aboutCode h1, .recentproject h1
    {
        font-size: calc(100vw/21);
    }
    .codetype
    {
        display: grid;
        grid-template-columns: 1fr 1.05fr 1fr;
        margin-bottom: 8em;
    }
    .codetype article
    {
        margin-top: 0.5em;
        padding: 0 1em;
    }
    .codetype article
    {
        width: auto; 
    }
    .airbnb, .shopify
    {
        max-width: 80%;
        margin: 0 auto;
    }
    
}
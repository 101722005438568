.menu
{
    background-color: white;
    color: black;
    position: fixed;
    top: 2.1825em;
    left: -100%;
    transition: left 2s;
    z-index: 3;
    width: 100%; 
    height: 100%;
}
.menu ul{
    list-style: none;
    padding-left: 3.5em;
    margin: 0;
}
.menu li
{
    margin: 1.5em 0;
    width: fit-content; 
    border-bottom: 1px solid white;
} 
.menu li:hover{
    border-bottom:  1px solid rgb(221, 88, 88);
    
}
.menu li a
{
    text-decoration: none; 
    color: black;
    font-size: 1.1em; 
    
}
.menu li a:hover{
    color:rgb(221, 88, 88);
}
.menu.invisible
{
    left: 0;
}

@media screen and (min-width: 640px) {
.menu
{
    position: static;
    background: none;
    display: block;
    grid-area: men;
    margin: 0.75em 0.75em;
    height: fit-content;
    width: initial;
}
.menu ul
{
    margin:0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style-type: none;
}
.menu li
{
    margin: 0;
    width: 100%; 
    padding: 0.5em 0;
    text-align: center;
    border-bottom: none;
}
.menu li:hover{
    border-bottom:  none; 
    
}
.menu li a
{
    font-size: 1.2em;
    color: white;
    font-family: Arial, Helvetica, sans-serif; 
    font-weight: 900;
    letter-spacing: 3px; 
    text-decoration: none;
}
.menu li:hover{
    color: rgb(221, 88, 88);
}
.notVisible{
    display: none;
}
}

@media screen and (min-width: 901px) {
    .menu
    {
        margin: 2em 0.75em;
    }
    .menu ul
    {
        display: flex;
        justify-content: space-evenly; 
        list-style-type: none;
    }

}
@import url('https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap');
.slide1{
    background-image: url('../../img-video/nepal.jpeg');
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
  }
.slide2{
    background-image: url('../../img-video/nepal2.jpeg');
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
}
.slide3{
    background-image: url('../../img-video/toronto.jpg');
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    opacity: 0.8;
    filter: brightness(50%);
}
.slideSection
{
    position: absolute;
    top: 53vh;
    left: 3em;
    color:white;  
}
.slideSection h1
{
    margin: 0;
    font-size: calc(100vw / 21 );
    font-family: 'Purple Purse', cursive;
    letter-spacing: 4px;
}
.aboutSection
{
    margin-top: -3px;
    padding : 7.5% 5%;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    grid-gap: 1em;
    background-color: rgb(241, 241, 241);
}
.aboutImg
{
  border-radius: 1em;
  width: calc(100vw);
  justify-self: center;
}
.aboutText h1, .strength h1, .meSection h1
{
    font-family: kepler-std-semicondensed-dis;
    font-size: calc(100vw/15);
    font-weight: 600;
    padding-bottom: 0.5em 
}
.aboutText p, .strength p
{
    margin-bottom: calc(100vw/20);
    line-height: 1.5;
    font-size: calc(100vw/40);
    text-align: justify;
}
.strength
{
    margin : 10% 10%;
}
.strength h1, .meSection h1
{
    font-size: calc(100vw/15);
    text-align: center;
}
.seeResume, .followInsta{
    margin: 0 auto;
    width: fit-content
}
.seeResume a, .followInsta a{
    background-color: transparent;
    transition: background-color 0.5s ease;
    border: 1px solid rgb(221, 88, 88);
    color: black;
    padding: 20px 34px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    letter-spacing: 3px;
    margin: 4px 2px;
    cursor: pointer;
}
.seeResume:hover, .followInsta:hover
{
    background-color:  rgb(221, 88, 88);
}
.meSection
{
    padding : 7.5% 7.5%;
    background-color: rgb(241, 241, 241);
}
.myImg
{
    text-align: center;
    margin-bottom: 1em;
}
.myImg img
{
    width: 85%;
    margin: 1em auto;
    padding: 5px;
    border: 0.5px solid black;
    border-radius: 0.5em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.45);
}



@media screen and (min-width: 640px) {

.slideSection
{   
    top: 40vh;
}
.aboutImg
{
    width: calc(100vw/1.5)
}
.aboutText h1
{
    font-size: calc(100vw/24); 
}
.aboutText p, .strength p
{
    font-size: calc(100vw/40); 
}
.strength h1, .meSection h1
{
    font-size: calc(100vw/18);
    text-align: center;
}
.myImg
{
   display: grid;
   grid-template-columns: 1fr 1fr;   
}
.myImg img:hover
{
    transform: translateY(5px);
    cursor: pointer; 
}
}
@media screen and (min-width: 840px) 
{
    .aboutImg
    {
        width: 300px;
    }
    .aboutText p, .strength p
    {
        font-size: calc(100vw/55); 
    }
    .myImg
    {
    grid-template-columns: 1fr 1fr 1fr;    
    }
}
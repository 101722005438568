/*----------------------------------------------------------
  Inspired by/taken code where offered from:
  Eric Meyer  :: http://meyerweb.com
  HTML5 Doctor  :: http://html5doctor.com
  and the HTML5 Boilerplate :: http://html5boilerplate.com
-------------------------------------------------------------------------------*/
/* Reset everything...
-------------------------------------------------------------------------------*/

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 1em;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    background: transparent;
  }
  
  main, article, aside, figure, footer, header, nav, section, details, summary, hgroup {
    display: block;
  }
  
  html,
  body {
    cursor: default;
  }
  
  /* Set all boxes to border boxes
    http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  html {
    box-sizing: border-box;
    overflow-y: scroll;/* force a vertical scrollbar to prevent a jumpy page */
    height: 100%;
    min-height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  /* Responsive images and other embedded objects */
  img, object, embed {
    max-width: 100%;
    height: auto;
  }
  
  /* Set all un-ordered lists to unbulletted */
  ul {
    list-style-type: none;
  }
  ol {
    list-style-type: decimal;
  }
  ul.bullets, ol {
    padding-left: 4em;
  }
  ul.bullets ul, ul ol, ol ol {
    padding-left: 2em;
  }
  
  blockquote, q {
    quotes: "“" "”" "‘" "’";
  }
  
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  
  blockquote.quotes:before,
  q.quotes:before {
    content: open-quote;
  }
  blockquote.quotes:after,
  q.quotes:after {
    content: close-quote;
  }
  
  a {
    margin: 0;
    padding: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
  
  del {
    text-decoration: line-through;
  }
  
  abbr[title], dfn[title] {
    border-bottom: 0.0625em dotted #000;
    cursor: help;
  }
  
  /* tables still need cellspacing="0" in the markup */
  table {
    border-collapse: separate;
    border-spacing: 0;
    font-size: inherit;
  }
  th {
    font-weight: bold;
    vertical-align: bottom;
  }
  td {
    font-weight: normal;
    vertical-align: top;
  }
  td, td img {
    vertical-align: top;
  }
  
  hr {
    display: block;
    height: 0.0625em;
    border: 0;
    border-top: 0.0625em solid #ccc;
    margin: 1em 0;
    padding: 0;
  }
  
  input, select, img, button, textarea {
    vertical-align: middle;
  }
  
  /*
  pre {
    white-space: pre;
    white-space: pre-wrap;
    white-space: pre-line;
    word-wrap: break-word;
  }*/
  
  small {
    font-size: 0.85em;
  }
  
  strong, em {
    font: inherit;
  }
  
  sub, sup {
    font-size: 0.75em;
    line-height: 0;
    position: relative;
  }
  sup {
    top: -0.5em;
  }
  sub {
    bottom: -0.25em;
  }
  
  pre, code, kbd, samp, var {
    font-family: monospace, sans-serif;
    font-size: 0.8em;
    cursor: text;
  }
  
  .clickable, [href], [for], [type="button"], [type="submit"], [type="checkbox"], [type="radio"], [type="file"], button {
    cursor: pointer;
  }
  
  [type="radio"], [type="checkbox"] {
    margin-top: -0.35em;
  }
  
  [type="search"] {
    -webkit-appearance: textfield;
  }
  
  select, input, textarea, button {
    font: inherit;
    padding: 0;
    margin: 0;
  }
  
  [disabled], [disabled] {
    cursor: default;
  }
  
  /* make buttons play nice in IE */
  button, [type="button"], [type="submit"] { 
    width: auto;
    overflow: visible;
    cursor: pointer;
  }
  button:focus, input:focus, select:focus{
    outline: none;
  }
  .clearfix:after { 
    content: " ";
    display: block;
    clear: both;
  }
@import url('https://fonts.googleapis.com/css2?family=Purple+Purse&display=swap');

.footer{
    background-color: #0a192b;
    color: white;
    text-align: center;
    padding: 1em 0;
}
.footer h1{
    margin-top: 0;
    font-size: calc(100vw / 23 );
    font-family: kepler-std-semicondensed-dis;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 2px;
}
.menu ul, .terms ul{
    padding: 0;
    margin: 1em 0 1.5em 0;
}
.menu ul li{
    list-style: none;
    display: inline-block
}
.menu a{
    text-decoration: none;
    color: white;
    display: inline-block;
    padding: 0.5em 0; 
    margin: 0 1em;
    border-bottom: 1px solid #0a192b;
}
.menu a:hover{
    border-bottom: 1px solid rgb(221, 88, 88);
    color: rgb(221, 88, 88);
}
.copyright
{
    display: inline-block;
    font-size: 0.8em;
}
.terms li
{
    list-style: none;
    display: inline-block;
    padding: 0.25em 1em;
}
.terms li a
{
    color: rgb(221, 88, 88);
    text-decoration: none;
    font-size: 0.75em;
    border-bottom: 1px solid ;
}
.icon{
    font-size: 1.5em;
    text-align: center;
    margin: 1em auto;
    color: white;   
}
.icon a{
    color:white;
    padding: 0.15em;
    
}
.icon i{
    padding: 1rem 1rem;
    background: #6f727f;
    transition: background-color 1s;
    border-radius: 1.5em;
}
#f:hover{
  background-color: #3b5998;
}
#t:hover{
    background-color: black;
}
#i:hover{
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}
#in:hover{
    background-color: #0e76a8;
}
#y:hover{
    background-color: #ff0000;  
}